<template>
    <b-container v-if="amortizationBook && amortizationBookRows" fluid>
        <b-row class="my-2">
            <b-col cols="6">
                <router-link :to="$route.path + '/edit'">
                    <b-btn variant="primary" class="mr-2">
                        Uredi knjigu amortizacija
                    </b-btn>
                </router-link>
                <b-btn variant="danger" class="mr-2" v-b-modal.deleteAmortizationBook>
                    Izbriši knjigu amortizacija
                </b-btn>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
            <table class="table b-table table-striped table-hover table-bordered text-center align-middle">
                <thead>
                <tr>
                    <th></th>
                    <th>
                        Obrazac PLDI-1043<br/>
                        <h3>Popisna lista dugotrajne imovine za period <u>{{amortizationBook.period}}</u></h3>
                    </th>
                    <th>Stranica {{amortizationBookRows.current_page}} od  {{amortizationBookRows.last_page}}</th>
                </tr>
                </thead>
            </table>
            </b-col>
        </b-row>

        <b-row class="my-2">
            <b-col>
            <table class="table b-table table-striped table-hover table-bordered text-left align-middle">
                <thead>
                <tr>
                    <th>Podaci o poreznom obvezniku ili nosiocu zajedničke djelatnosti: </th>
                    <th colspan="2">Podaci o registrovanoj djelatnosti: </th>
                </tr>
                <tr>
                    <td><b>1. JMB: </b>{{amortizationBook.client.owner.jmbg}}</td>
                    <td colspan="2"><b>4. JIB: </b>{{amortizationBook.client.id_number}}</td>
                </tr>
                <tr>
                    <td><b>2. Prezime i ime:  </b>{{amortizationBook.client.owner.name}}</td>
                    <td colspan="2"><b>5. Naziv: </b>{{amortizationBook.client.name}}</td>
                </tr>
                <tr>
                    <td><b>3. Adresa sjedišta:  </b>{{amortizationBook.client.owner.address}}</td>
                    <td><b>6. Adresa prebivališta: </b>{{amortizationBook.client.address}}</td>
                    <td><b>7. Vrsta djelatnosti (šifra, naziv): </b>{{amortizationBook.client.activity.code}}, {{amortizationBook.client.activity.name}}</td>
                </tr>
                </thead>
            </table>
            </b-col>
        </b-row>

        <b-row class="flex-center my-4">
            <h4>Podaci o dugotrajnoj imovini</h4>
        </b-row>

        <b-row>
            <b-col>
                <roza-table :items="amortizationBookRows"
                            :fields="amortizationBookFields"
                            :prenosFields="prenosFields"
                            :prenosColspan="4"
                            :colIndex="8"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :actions="actions"
                            @action="action">
                </roza-table>

                <b-pagination align="right"
                              @input="getRows"
                              :total-rows="amortizationBookRows.total"
                              v-model="currentPage"
                              :per-page="perPage">
                </b-pagination>
            </b-col>
        </b-row>

        <b-row class="d-flex">
            <b-col md="12" class="text-right">
                <b-btn class="pull-right m-2"
                       v-b-modal.addRowModal
                       variant="primary">
                    Dodaj zapis
                </b-btn>
            </b-col>
        </b-row>

        <b-modal centered
                 id="addRowModal"
                 title="Dodaj zapis"
                 hide-footer>
            <b-form v-on:submit.prevent="addRow">
                <b-form-group
                        :label-cols-md="4"
                        label="Naziv sredstva ili prava:"
                        label-for="medium_name">
                    <b-form-input id="medium_name" name="medium_name" required
                                  type="text"
                                  size="sm"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Datum nabavke ili ulaganja:"
                        label-for="purchase_date">
                    <date-picker format="DD.MM.YYYY."
                                 v-model="purchase_date"
                                 :lang="$parent.hrv"
                                 :first-day-of-week="1"
                                 class="w-100"
                                 :input-attr="{required: true}"
                                 id="purchase_date">
                    </date-picker>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Broj dokumenta:"
                        label-for="document_number">
                    <b-form-input id="document_number" name="document_number" required
                                  type="text"
                                  size="sm"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Nabavna vrijednost:"
                        label-for="purchase_value">
                    <b-form-input id="purchase_value" name="purchase_value" required
                                  type="number"
                                  min="0" step="0.001"
                                  size="sm"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Knjigovodstvena vrijednost:"
                        label-for="book_value">
                    <b-form-input id="book_value" name="book_value" required
                                  type="number"
                                  min="0" step="0.001"
                                  size="sm"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Vijek trajanja:"
                        label-for="lifetime">
                    <b-form-input id="lifetime" name="lifetime" required
                                  type="text"
                                  size="sm"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Stopa amortizacije:"
                        label-for="amortization_rate">
                    <b-form-input id="amortization_rate" name="amortization_rate" required
                                  type="number"
                                  min="0" step="0.001"
                                  size="sm"
                    ></b-form-input>
                </b-form-group>
                <b-btn variant="primary" type="submit" class="pull-right">Potvrdi</b-btn>
            </b-form>
        </b-modal>

        <b-modal centered
                 ref="deleteRow" title="Potvrda"
                 @ok="deleteRow(deleteRowId)"
                 @cancel="deleteRowId = 0"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati red?</p>
        </b-modal>

        <b-modal centered
                 id="deleteAmortizationBook"
                 title="Potvrda"
                 @ok="deleteAmortizationBook"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati knjigu amortizacija?</p>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        name: 'ShowAmortizationBook',
        data (){
            return {
                amortizationBook: null,
                amortizationBookRows: null,
                amortizationBookFields: [
                    {
                        key: 'position',
                        label: 'Broj'
                    },
                    {
                        key: 'medium_name',
                        label: 'Naziv sredstva ili prava'
                    },
                    {
                        key: 'purchase_date',
                        label: 'Datum nabavke ili ulaganja',
                        date: true
                    },
                    {
                        key: 'document_number',
                        label: 'Broj dokumenta'
                    },
                    {
                        key: 'purchase_value',
                        label: 'Nabavna vrijednost',
                        class: 'text-right'
                    },
                    {
                        key: 'book_value',
                        label: 'Knjigovodstvena vrijednost',
                        class: 'text-right'
                    },
                    {
                        key: 'lifetime',
                        label: 'Vijek trajanja'
                    },
                    {
                        key: 'amortization_rate',
                        label: 'Stopa amortizacije'
                    },
                    {
                        key: 'amortization_amount',
                        label: 'Iznos amortizacije (Kolone 12x15 / 100) ',
                        class: 'text-right'
                    },
                    {
                        key: 'book_value_final',
                        label: 'Knjigovodstvena vrijednost sredstava na kraju godine (Kolone 13-16)',
                        class: 'text-right'
                    }
                ],
                prenosFields: [
                    'purchaseValueTotal',
                    'bookValueTotal',
                    '',
                    '',
                    'amortizationAmountTotal',
                    'bookValueFinalTotal'
                ],
                actions: {
                    delete: {
                        name: 'Izbriši',
                        icon: 'fa fa-trash'
                    }
                },
                deleteRowId: 0,
                currentPage: 1,
                perPage: 5,
                purchase_date: null
            }
        },
        methods: {
            getRows() {
                axiOsta.get(api.amortizationBooks + this.$route.params.id + api.rows, {
                    params: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.amortizationBookRows = response.data;
                    }
                })
            },
            addRow: function(e) {
                const formData = new FormData(e.target);
                formData.append('amortization_book_id', this.amortizationBook.id);
                formData.append('purchase_date', this.$moment(this.purchase_date).format("YYYY-MM-DD"));
                axiOsta.post(api.amortizationBookRows, formData).then(response => {
                    if (response.status === 201) {
                        this.getRows();
                    }
                });
            },
            deleteRow(id) {
                axiOsta.delete(api.amortizationBookRows + id).then(response => {
                    if (response.status === 200) {
                        this.getRows();
                    }
                });
            },
            deleteModal(id) {
                this.deleteRowId = id;
                this.$refs.deleteRow.show();
            },
            deleteAmortizationBook() {
                axiOsta.delete(api.amortizationBooks + this.amortizationBook.id).
                then(response => {
                    this.$router.replace('/amortization_books');
                })
            },
            action: function (action, item) {
                switch (action) {
                    case 'delete':
                        this.deleteModal(item.id);
                        break;
                }
            },
        },
        created() {
            this.getRows();
            axiOsta.get(api.amortizationBooks + this.$route.params.id).
            then(response => {
                if (response.status === 200) {
                    this.amortizationBook = response.data;
                }
            });
        }
    }
</script>